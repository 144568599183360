import styled from "styled-components"

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { ArrowRightAltRounded } from "@material-ui/icons"
import { format } from "date-fns"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TMButtonProps } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"

export interface IDateFieldProps extends TMButtonProps {
  startDate: Date | null
  endDate: Date | null
  onClick: () => void
  appearance?: "button" | "dropdown"
}

export function DateField({
  startDate,
  endDate,
  onClick,
  color = "default",
  appearance = "button",
}: IDateFieldProps) {
  const { t, langKeys } = useTranslate()
  const handleClick = () => {
    onClick()
  }

  const formatType = isSameYear(startDate, endDate)
    ? DATE_FORMAT_SAME_YEAR
    : DATE_FORMAT

  function DateFieldLabel() {
    return !!(startDate && endDate) ? (
      <>
        {startDate && format(startDate, formatType)}
        <StyledArrowRightAltRounded />
        {endDate && format(endDate, formatType)}
      </>
    ) : (
      <>{t(langKeys.date)}</>
    )
  }

  if (appearance === "dropdown") {
    const dateRangeStr =
      startDate && endDate
        ? `${format(startDate, formatType)} - ${format(endDate, formatType)}`
        : ``

    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="datefield-dropdown">{t(langKeys.time)}</InputLabel>
        <Select
          label={"Time"}
          open={false}
          onOpen={() => {
            handleClick()
          }}
          value={dateRangeStr}
          onChange={() => {}}
          inputProps={{ name: "time", id: "datefield-dropdown" }}
          fullWidth
        >
          <MenuItem value={dateRangeStr}>{dateRangeStr}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <DropdownButton
      onClick={handleClick}
      color={color}
      displayValue={<DateFieldLabel />}
    />
  )
}

const StyledArrowRightAltRounded = styled(ArrowRightAltRounded)`
  margin: 0 ${spacing.S};
`

const DATE_FORMAT = "MMM d y"
const DATE_FORMAT_SAME_YEAR = "eee, MMM d"

const isSameYear = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) {
    return false
  }
  return startDate.getFullYear() === endDate.getFullYear()
}
