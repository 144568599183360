import { useContext } from "react"
import styled from "styled-components"

import {
  FirstDayOfWeek,
  MonthType,
  OnDatesChangeProps,
} from "@datepicker-react/hooks"
import { IconButton } from "@material-ui/core"

import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { colorsV2, greyScale } from "src/ui/colors"
import { DateRangePickerContext } from "src/ui/DateRangePicker/DateRangePickerContext"
import LeftIcon from "src/ui/icons/chevron-left.svg"
import RightIcon from "src/ui/icons/chevron-right.svg"
import CloseIcon from "src/ui/icons/close.svg"
import { spacing } from "src/ui/spacing"

import { Month } from "./Month"

export type CalendarProps = {
  activeMonths: MonthType[]
  firstDayOfWeek: FirstDayOfWeek
  onClose: () => void
  defaultPresetKey: PresetKey
  hidePresets?: PresetKey[]
  onSelectPreset: ({
    presetKey,
    clearSelection,
  }: {
    presetKey: PresetKey
    clearSelection?: boolean
  }) => void
  open: boolean
  state: OnDatesChangeProps
}

export function Calendar({
  activeMonths,
  firstDayOfWeek,
  onClose,
  defaultPresetKey,
  hidePresets,
  onSelectPreset,
  open,
  state,
}: CalendarProps) {
  const { t } = useTranslate()
  const { goToPreviousMonths, goToNextMonths } = useContext(
    DateRangePickerContext
  )

  if (!open) {
    return null
  }

  const nbrOfMonths = activeMonths.length

  return (
    <Box>
      <CloseButton aria-label="close" size="small" onClick={onClose}>
        <CloseIcon width={24} color={colorsV2.textPrimary} />
      </CloseButton>

      <CalendarBox $nbrOfMonths={nbrOfMonths}>
        <PreviousButton size="small" onClick={goToPreviousMonths}>
          <LeftIcon width={24} height={24} />
        </PreviousButton>

        {activeMonths.map((month) => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}

        <NextButton size="small" onClick={goToNextMonths}>
          <RightIcon width={24} height={24} />
        </NextButton>
      </CalendarBox>
      <BoxBottom>
        <Presets>
          <MButton
            variant="minimal"
            onClick={() => onSelectPreset({ presetKey: "DAY" })}
            hidden={hidePresets?.includes("DAY")}
          >
            {t(langKeys.last_day)}
          </MButton>
          <MButton
            variant="minimal"
            onClick={() => onSelectPreset({ presetKey: "WEEK" })}
            hidden={hidePresets?.includes("WEEK")}
          >
            {t(langKeys.last_week)}
          </MButton>
          <MButton
            variant="minimal"
            onClick={() => onSelectPreset({ presetKey: "MONTH" })}
            hidden={hidePresets?.includes("MONTH")}
          >
            {t(langKeys.last_month)}
          </MButton>
          <MButton
            variant="minimal"
            onClick={() => onSelectPreset({ presetKey: "YEAR" })}
            hidden={hidePresets?.includes("YEAR")}
          >
            {t(langKeys.last_year)}
          </MButton>
        </Presets>

        <MButton
          variant="minimal"
          onClick={() =>
            onSelectPreset({
              presetKey: defaultPresetKey,
              clearSelection: true,
            })
          }
          disabled={!state.startDate || !state.endDate}
        >
          {t(langKeys.clear_selection)}
        </MButton>
      </BoxBottom>
    </Box>
  )
}

export type PresetKey = "DAY" | "WEEK" | "MONTH" | "YEAR"

export const BREAKPOINT_CALENDAR_FLOATING = "1000px"
const BREAKPOINT_CALENDAR_2COL = "800px"

const CloseButton = styled(IconButton)`
  position: fixed;
  margin: ${spacing.XS};
  top: 0;
  right: 0;
  z-index: 10;
  background: ${colorsV2.neutralLight};

  &:hover {
    background: ${greyScale[200]};
  }

  @media (min-width: ${BREAKPOINT_CALENDAR_FLOATING}) {
    display: none;
  }
`

const Box = styled.div`
  width: 90dvw;
  height: 100dvh;
  overflow-y: auto;
  background: ${colorsV2.neutralLight};
  z-index: 1;
  border: 1px solid ${greyScale[200]};
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 14px;

  @media (min-width: ${BREAKPOINT_CALENDAR_FLOATING}) {
    height: auto;
    width: auto;
    margin-top: ${spacing.S};
    top: 100%;
  }
`

const Presets = styled.div`
  position: relative;
  display: flex;
  gap: ${spacing.S};
  flex-wrap: wrap;
`

const CalendarBox = styled.div<{ $nbrOfMonths: number }>`
  position: relative;
  display: grid;
  margin: ${spacing.XL} ${spacing.M};
  justify-content: center;
  gap: ${spacing.XL2};

  @media (min-width: ${BREAKPOINT_CALENDAR_2COL}) {
    grid-template-columns: repeat(${(props) => props.$nbrOfMonths}, 1fr);
    margin: ${spacing.XL};
  }
`

const PreviousButton = styled(IconButton)`
  position: absolute;
  top: 0;
  left: 0;
`

const NextButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.M} ${spacing.M};
  border-top: 1px solid ${colorsV2.divider};
`
