import { useMemo, useRef, useState } from "react"
import styled from "styled-components"

import {
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
  UseDatepickerProps,
} from "@datepicker-react/hooks"
import { Popper, PopperProps, useMediaQuery } from "@material-ui/core"
import {
  addYears,
  isFuture,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns"

import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { DateRangePickerContext } from "src/ui/DateRangePicker/DateRangePickerContext"

import {
  BREAKPOINT_CALENDAR_FLOATING,
  Calendar,
  CalendarProps,
  PresetKey,
} from "./Calendar"
import { DateField, IDateFieldProps } from "./DateField"

export type TDateRange = {
  startDate: Date
  endDate: Date
}

export type DateRangePickerProps = {
  startDate: Date | null
  endDate: Date | null
  onDateRangeChange: (dr: TDateRange) => void
  dateRangeProps: Omit<
    UseDatepickerProps,
    | "startDate"
    | "endDate"
    | "focusedInput"
    | "onDatesChange"
    | "maxBookingDate"
  >
  defaultPresetKey?: PresetKey
  calendarPlacement?: PopperProps["placement"]
  appearance?: IDateFieldProps["appearance"]
  hidePresets?: CalendarProps["hidePresets"]
}

export function DateRangePicker({
  startDate,
  endDate,
  onDateRangeChange,
  dateRangeProps,
  defaultPresetKey = "MONTH",
  calendarPlacement = "top",
  appearance = "button",
  hidePresets,
}: DateRangePickerProps) {
  const [state, setState] = useState<OnDatesChangeProps>({
    startDate,
    endDate,
    focusedInput: "startDate",
  })

  const [showCalendar, setShowCalendar] = useState(false)
  const dateFieldRef = useRef<HTMLDivElement>(null)

  const isFloating = useMediaQuery(
    `(min-width: ${BREAKPOINT_CALENDAR_FLOATING})`
  )
  const placement = isFloating ? calendarPlacement : undefined

  const maxBookingDate = useMemo(() => {
    if (state.startDate && state.endDate) {
      return new Date()
    }

    const maximumRangeDate = addYears(state.startDate ?? new Date(), 2)

    if (isFuture(maximumRangeDate)) {
      return new Date()
    }

    return maximumRangeDate
  }, [state])

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToNextMonths,
    goToPreviousMonths,
    goToDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isStartDate,
    isEndDate,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    maxBookingDate,
    changeActiveMonthOnSelect: false,
    ...dateRangeProps,
  })

  function handleDateChange(data: OnDatesChangeProps) {
    if (!data.focusedInput && data.startDate && data.endDate) {
      setState({ ...data, focusedInput: START_DATE })
      onDateRangeChange({ startDate: data.startDate, endDate: data.endDate })
    } else {
      setState({ ...data, endDate: null })
    }
  }

  function handleSelectPreset({
    presetKey,
    clearSelection,
  }: {
    presetKey: PresetKey
    clearSelection?: boolean
  }) {
    let startDate: Date
    let endDate: Date

    switch (presetKey) {
      case "DAY":
        startDate = subDays(new Date(), 1)
        endDate = new Date()
        break
      case "WEEK":
        startDate = subWeeks(new Date(), 1)
        endDate = new Date()
        break
      case "MONTH":
        startDate = subMonths(new Date(), 1)
        endDate = new Date()
        break
      case "YEAR":
        startDate = subYears(new Date(), 1)
        endDate = new Date()
        break
      default:
        throw Error("Unknown date range preset value:", presetKey)
    }

    setState((state) => ({
      ...state,
      startDate: clearSelection ? null : startDate,
      endDate: clearSelection ? null : endDate,
      focusedInput: START_DATE,
    }))

    goToDate(startDate)

    onDateRangeChange({ startDate, endDate })
  }

  const handleDateFieldClick = () => {
    setShowCalendar((open) => !open)
  }

  function handleCloseCalendar() {
    setShowCalendar(false)
  }

  return (
    <DateRangePickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        isStartDate,
        isEndDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        goToPreviousMonths,
        goToNextMonths,
      }}
    >
      <Box ref={dateFieldRef}>
        <DateField
          startDate={state.startDate}
          endDate={state.endDate}
          onClick={handleDateFieldClick}
          appearance={appearance}
        />

        <MPopper
          open={!!showCalendar}
          anchorEl={dateFieldRef.current}
          placement={placement}
        >
          <Calendar
            open={showCalendar}
            onClose={handleCloseCalendar}
            onSelectPreset={handleSelectPreset}
            firstDayOfWeek={firstDayOfWeek}
            activeMonths={activeMonths}
            state={state}
            defaultPresetKey={defaultPresetKey}
            hidePresets={hidePresets}
          />
        </MPopper>

        {/* This backdrop element is needed to detect click events outside the
        rendered calendar; MUIs ClickAwayListener doesn't work. */}
        {!!showCalendar && <Backdrop onClick={handleCloseCalendar}></Backdrop>}
      </Box>
    </DateRangePickerContext.Provider>
  )
}

const Box = styled.div`
  position: relative;
`

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: transparent;
`

const MPopper = styled(Popper)`
  z-index: ${Z_INDEX_ABOVE_MODAL};
`
